



























































































import { Component, Mixins, Prop, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import RadioOkNg from '@/components/molecules/RadioOkNg.vue'
import ImageExpansion from '@/components/modules/drills/molecules/ImageExpansion.vue'
import ImageExpansionAudioDoc from '@/components/modules/drills/molecules/ImageExpansionAudioDoc.vue'
import ScrollGuide from '@/components/modules/drills/atoms/ScrollGuide.vue'
import { S3PathType } from '@/models/api/s3PathType'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import PlayAudioButon from '@/components/molecules/PlayAudioButon.vue'

// 設問番号の表示にローカル変数を宣言（時間に余裕あれば変更する）
let _sProblemIndex = 0

@Component({
  components: {
    ButtonBase,
    RadioOkNg,
    ImageExpansion,
    ImageExpansionAudioDoc,
    ScrollGuide,
    PlayAudioButon,
  },
})
export default class DrillCorrect extends Mixins(ClassModeMethods) {
  @Prop()
  customParam?: {
    currentPageType?: string
    classCategoryCode?: string
  }

  @Prop({ default: false })
  isTeacher!: boolean

  private get isReport() {
    return this.$route.path.includes('report')
  }

  private get subjectCode() {
    if (this.isReport) {
      return this.$route.query?.subjectCode || Vue.prototype.$cookies.get('dataGdls').subjectCode
    }
    return Vue.prototype.$cookies.get('dataGdls').subjectCode
  }

  private get classMode() {
    if (this.isReport) {
      return this.$route.query?.classMode || Vue.prototype.$cookies.get('dataGdls').classMode
    }
    return Vue.prototype.$cookies.get('dataGdls').classMode
  }

  private get isVertical() {
    // 国語の場合はtrue、ただし入試対策モードの場合はfalseになる
    return this.subjectCode === 'ko' && this.classMode !== 'NY'
  }

  private get processed(): boolean {
    return this.$store.getters['drills/processed']
  }

  /**
   * Web APIを通して取得した画像URLを取得
   * [{d: '...', m: '...', j: '...'}, ...]
   *
   * @return 画像URL群
   */
  private get imageUrls(): any[] {
    return this.$store.getters['drills/imageUrls']
  }

  /**
   * Web APIを通して取得した画像名を取得
   * 問題ページの場合も配列に1つの連想配列が渡ってくる
   * [{d: '...', m: '...', j: '...'}]
   *
   * @return 画像名群
   */
  private get imagePaths(): any[] {
    return this.$store.getters['drills/imagePaths']
  }

  private get questionNumber(): number[] {
    return this.$store.getters['drills/questionNumber']
  }

  private get correctInput(): any[] {
    return this.$store.getters['drills/correctInput']
  }

  private get correctResult(): any[] {
    return this.$store.getters['drills/correctResult']
  }

  private get sProblemIdForQuestions(): any[] {
    return this.$store.getters['drills/sProblemIdForQuestions']
  }

  /**
   * 音声URLを取得(入試モードの場合のみ)
   */
  private audioUrls(pageIndex: number) {
    // drills/audioUrls: [['url1', 'url2', ...], ['url1', 'url2'...]]
    const audioUrls = this.$store.getters['drills/audioUrls']
    console.log('!!TEST')
    console.log(audioUrls)
    if (!audioUrls) return []
    if (!audioUrls[pageIndex]) return []

    return audioUrls[pageIndex]
  }

  /**
   * 放送原稿の有無(入試モードの場合のみ)
   */
  private hasAudioDoc(pageIndex: number) {
    return this.isEntranceMode && (this.imageUrls[pageIndex]?.h || []).length > 0
  }

  /**
   * 授業種別コード
   *
   * @return 授業種別コード
   */
  private get classCategoryCode(): string {
    if (this.customParam?.classCategoryCode) return this.customParam.classCategoryCode

    return Array.isArray(this.$route.query.classCategoryCode)
      ? this.$route.query.classCategoryCode[0] || ''
      : this.$route.query.classCategoryCode || ''
  }

  private currentPageType(): string {
    if (this.customParam?.currentPageType) return this.customParam.currentPageType

    return this.$route.query.pageType.toString()
  }

  private updateCorrectInput(event: boolean, pageIndex: number, questionIndex: number) {
    Vue.prototype.$logger.log(`updateCorrectInput ${event} ${pageIndex} ${questionIndex}`)

    this.$store.commit('drills/setCorrectInputDetail', {
      value: event,
      pageIndex: pageIndex,
      questionIndex: questionIndex,
    })
  }

  /**
   * 該当ページの問題が大問かどうか取得
   *
   * @param pageIndex ページ番号
   * @return 大問かどうかの真偽値
   */
  private isLProblem(pageIndex: number): boolean {
    return this.$store.getters['drills/isLProblems'][pageIndex]
  }

  /**
   * 問題が小問かどうか取得
   *
   * @return 小問かどうかの真偽値
   */
  private isSProblem(pageIndex: number): boolean {
    return !this.isLProblem(pageIndex)
  }

  /**
   * ローカル変数の取得（共通化不可）
   */
  protected getSProblemIndex(): number {
    return _sProblemIndex
  }

  /**
   * ローカル変数のセット（共通化不可）
   */
  protected setSProblemIndex(value: number): void {
    _sProblemIndex = value
  }

  /**
   * ローカル変数のインクリメント（共通化不可）
   */
  protected sProblemIndexMethod(s3_path_type: S3PathType, imageIndex: number, pageIndex: number): void {
    // 小問に複数画像(***m02.png, ***m03.png)がある場合はfalse
    const regexp = new RegExp(s3_path_type + '0[2-9].png$')
    const filename = this.imagePaths[pageIndex][s3_path_type][imageIndex]

    // 問題インデックス
    if (!regexp.test(filename)) _sProblemIndex++
  }

  /**
   * 小問画像が一つ目かどうか
   *
   * @return 小問かどうかの真偽値
   */
  protected isSProblemFirstImage(s3_path_type: S3PathType, imageIndex: number, pageIndex: number): boolean {
    // 小問に複数画像(***m02.png, ***m03.png)がある場合はfalse
    const regexp = new RegExp(s3_path_type + '0[2-9].png$')
    const filename = this.imagePaths[pageIndex][s3_path_type][imageIndex]

    return this.isSProblem(pageIndex) && !regexp.test(filename)
  }

  /**
   * 該当ページの大問毎の設問数
   *
   * @return 設問数
   */
  private questionNumberWithSProblem(pageIndex: number): number[] {
    return this.$store.getters['drills/questionNumberWithSProblem'][pageIndex]
  }

  private pageQuestionStartNumber(end: number, pageIndex: number): number {
    return (
      this.questionNumberWithSProblem(pageIndex)
        .slice(0, end)
        .reduce((sum, element) => sum + element, 0) + 1
    )
  }

  /**
   * 現在のページ番号
   *
   * @param pageIndex imageUrls配列のindex番号
   * @return ページ番号
   */
  private currentPage(pageIndex: number): number {
    return this.$store.getters['drills/drillData'].data[pageIndex].page
  }

  /**
   * 解説動画画面に遷移する
   */
  public transferExplanatoryVideo(data: { pageIndex: number; questionIndex: number }): void {
    let path = '/student/s-problems/explanatory-video'
    if (this.isReport) {
      const content: string = (this.$route.query.content as string) || ''
      const type = this.$route.query.type || ''
      const classMode = this.$route.query.classMode || ''
      path = `/${
        Vue.prototype.$cookies.get('authGdls').webRole
      }/report/s-problems/explanatory-video?content=${encodeURIComponent(content)}&type=${type}&classMode=${classMode}`
    }
    this.$router.push({
      path: path,
      query: {
        sProblemId: this.sProblemIdForQuestions[data.pageIndex][data.questionIndex - 1].sProblemId,
        resultDrillId: this.$route.query.resultDrillId || this.$route.params.resultDrillId,
        historyBackUrl: encodeURIComponent(this.$route.fullPath),
      },
    })
  }

  private movieButtonMessage(pageIndex: number, questionIndex: number): string {
    const pageNum = this.currentPage(pageIndex)
    const idForQuestions = this.$store.getters['drills/sProblemIdForQuestions'][pageIndex]
    let title = ''
    if (this.isLProblem(pageIndex)) {
      // 大問の場合は「大問1（ページ番号）」と表示
      title = `大問${pageNum}`
    } else {
      const resultsCount = idForQuestions[questionIndex].resultsCount
      if (resultsCount === 1) {
        // 小問と設問が1対1の場合は「1-1（ページ番号-小問番号）」と表示
        title = `${pageNum}-${questionIndex + 1}`
      } else {
        // 小問と設問が1対多の場合は「1-1〜1-3（ページ番号-小問番号〜ページ番号-小問番号）」と表示
        const startIndex = idForQuestions.findIndex(
          (idForQuestion: any) => idForQuestion.sProblemId === idForQuestions[questionIndex].sProblemId
        )
        title = `${pageNum}-${startIndex + 1}〜${pageNum}-${startIndex + resultsCount}`
      }
    }

    const message = idForQuestions[questionIndex].isMoviePlayed ? '視聴済み' : '視聴する'
    return `${title} ${message}`
  }
}
