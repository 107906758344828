namespace Config {
  export const drillModules = {
    // 自己採点縦
    SELF_VR: ['TitleWithPenSwitcher', 'QuestionImageVertical', 'Transition'],
    // 自己採点横
    SELF_HR: ['TitleWithPenSwitcher', 'QuestionImageHorizontal', 'Transition'],

    // 全自己採点
    SCORING_ALL: ['Title', 'CorrectGuid', 'Correct', 'Transition'],

    // 全自己採点結果
    RESULT_ALL: ['Title', 'ResultGuide', 'Correct', 'Transition'],

    // 全自己採点結果（演習・宿題の場合）
    RESULT_ALL_PREDICTED_SCORE: ['Title', 'ResultGuide', 'PredictedScore', 'Correct', 'Transition'],

    // 一問一答テキスト縦
    AUTO_ONE_TEXT_VR: ['TitleWithPenSwitcher', 'QuestionImageVertical', 'Transition'],

    // 一問一答テキスト横
    AUTO_ONE_TEXT_HR: ['TitleWithPenSwitcher', 'QuestionImageHorizontal', 'Transition'],

    // 自動採点結果
    RESULT_AUTO: ['Title', 'AutoResult', 'Transition'],
  }
}

export default Config
