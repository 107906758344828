



import { Component, Vue } from 'vue-property-decorator'

@Component
export default class CorrectGuide extends Vue {
  private mounted() {
    Vue.prototype.$logger.info('-- CorrectGuide mounted')
  }
}
