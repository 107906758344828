





import { Component, Mixins, Vue } from 'vue-property-decorator'
import Title from '@/components/modules/drills/atoms/Title.vue'
import CorrectGuid from '@/components/modules/drills/atoms/CorrectGuide.vue'
import Correct from '@/components/modules/drills/organisms/Correct.vue'
import Transition from '@/components/modules/drills/organisms/Transition.vue'

import DrillsConfig from '@/mixins/drills/Config'
import DrillWebApi from '@/mixins/drills/WebApi'
import LessonStudent from '@/mixins/action-cable/LessonStudent/index'
import VueRouter from 'vue-router'

Component.registerHooks(['beforeRouteUpdate'])

@Component({
  components: {
    Title,
    CorrectGuid,
    Transition,
    Correct,
  },
})
export default class DrillScoring extends Mixins(DrillWebApi, LessonStudent) {
  private get isReport() {
    return this.$route.path.includes('report')
  }

  private get drillModules() {
    const drillScore = DrillsConfig.drillModules.SCORING_ALL
    //remove component Transition
    const resultDrill = [...drillScore]
    if (this.isReport) {
      resultDrill.pop()
    }
    return resultDrill
  }

  protected get lessonId() {
    return this.$store.getters['lessonActionCable/lessonId']
  }

  /**
   * 授業種別コード
   *
   * @return 授業種別コード
   */
  private get classCategoryCode(): string {
    return Array.isArray(this.$route.query.classCategoryCode)
      ? this.$route.query.classCategoryCode[0] || ''
      : this.$route.query.classCategoryCode || ''
  }

  /**
   * カリキュラムモードコード
   *
   * @return カリキュラムモードコード
   */
  private get curriculumModeCode(): string {
    return Array.isArray(this.$route.query.curriculumModeCode)
      ? this.$route.query.curriculumModeCode[0] || ''
      : this.$route.query.curriculumModeCode || ''
  }

  private setNoticeCurrentPageText() {
    let result = ''

    if (this.classCategoryCode === 'EN') {
      result = '演習正誤結果登録中'
    } else if (this.classCategoryCode === 'RK') {
      result = '理解度テスト正誤結果登録中'
    } else if (this.classCategoryCode === 'KK') {
      result = '確認テスト正誤結果登録中'
    } else if (this.classCategoryCode === 'SY') {
      result = '宿題正誤結果登録中'
    }

    // 補助教材選択中
    if (this.curriculumModeCode === 'OO') {
      result = '補助教材テスト正誤結果登録中'
    }

    this.$store.commit('drills/setNoticeCurrentPageText', result)
  }

  protected created() {
    this.setNoticeCurrentPageText()
  }

  protected async mounted(): Promise<any> {
    Vue.prototype.$logger.info('-- DrillScoring mounted')

    switch (this.$route.query.pageType) {
      case 'scoring_all':
        // 自己採点に関連する情報を取得するAPIを呼ぶ
        await this.startScoringAllApi({
          lessonId: this.lessonId,
          resultDrillId: Number(this.$route.query.resultDrillId),
        })

        break
      case 'scoring_unit':
        // 自己採点に関連する情報を取得するAPIを呼ぶ
        await this.startScoringUnitApi({
          lessonId: this.lessonId,
          resultDrillId: Number(this.$route.query.resultDrillId),
          page: Number(this.$route.query.page),
        })

        break
    }
  }
}
