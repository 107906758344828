










import { Component, Vue, Prop } from 'vue-property-decorator'
import ImageVertical from '@/components/modules/drills/molecules/ImageVertical.vue'
import ImageHorizontal from '@/components/modules/drills/molecules/ImageHorizontal.vue'

@Component({
  components: {
    ImageVertical,
    ImageHorizontal,
  },
})
export default class ImageExpansion extends Vue {
  private show = false

  private pageIndex: number | null = null

  @Prop()
  subjectCode?: string

  private get innerSubjectCode() {
    return this.subjectCode ? this.subjectCode : Vue.prototype.$cookies.get('dataGdls').subjectCode
  }

  private get classMode() {
    return Vue.prototype.$cookies.get('dataGdls').classMode
  }

  private get isVertical() {
    // 国語の場合はtrue、ただし入試対策モードの場合は読解文が存在する場合のみtrueになる
    if (this.classMode === 'NY') {
      return this.innerSubjectCode === 'ko' && this.hasDokkai
    } else {
      return this.innerSubjectCode === 'ko'
    }
  }

  private get hasDokkai() {
    const imageUrls = this.$store.getters['drills/imageUrls']
    if (!this.pageIndex) return false
    if (!imageUrls) return false
    if (!imageUrls[this.pageIndex]) return false
    if (!imageUrls[this.pageIndex].d) return false
    return imageUrls[this.pageIndex].d.length > 0
  }

  get showState(): { [key: string]: string } {
    return {
      display: this.show ? 'block' : 'none',
    }
  }

  private showImage(pageIndex: number): void {
    this.pageIndex = pageIndex
    this.show = true
  }

  private hideImage(): void {
    this.pageIndex = null
    this.show = false
  }

  /**
   * 現在のページ番号
   *
   * @param pageIndex imageUrls配列のindex番号
   * @return ページ番号
   */
  private currentPage(pageIndex: number): number {
    Vue.prototype.$logger.log('-- currentPage')
    return this.$store.getters['drills/drillData'].data[pageIndex].page
  }
}
