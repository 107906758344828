





import { Component, Mixins, Vue } from 'vue-property-decorator'
import LessonStudent from '@/mixins/action-cable/LessonStudent/index'
import VueRouter from 'vue-router'

Component.registerHooks(['beforeRouteUpdate'])

@Component
export default class Title extends Mixins(LessonStudent) {
  private subjectCode = Vue.prototype.$cookies.get('dataGdls').subjectCode

  private readonly subjectNames = { ko: '国語', su: '算数/数学', ei: '英語', ri: '理科', sh: '社会' }

  private get subjectName() {
    return this.subjectNames[this.subjectCode]
  }

  private title = ''

  private async mounted() {
    Vue.prototype.$logger.info('-- Title mounted')
    // タイトル取得APIを呼ぶ
    try {
      const res = await Vue.prototype.$http.httpWithToken.get(
        `/drill/title?resultDrillId=${Number(this.$route.query.resultDrillId)}`
      )
      this.title = res.data.title
      this.$store.commit('drillsV3/setTitleResult', res.data.title)
    } catch {
      // TODO エラー処理
      alert('エラーが発生しました。')
      throw 'Drill Error!'
    }

    this.noticeGroup()
  }

  public beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    Vue.prototype.$logger.info('-- SelectSubject beforeRouteUpdate')

    this.noticeGroup()

    next()
  }

  private async noticeGroup() {
    Vue.prototype.$logger.log('-- noticeGroup')
    Vue.prototype.$logger.log(this.noticeCurrentPageText)
    // このページでは固定文言のためページ遷移時またはbeforeRouteUpdate時に現在ページを伝える
    this.noticeOrStackCurrentPage({
      currentPage: this.noticeCurrentPageText,
      pageTitle: `${this.subjectName}） ${this.title}`,
    })

    // router.pushではsetTimeoutがクリアされないためセットする前にクリアして初期化しておく
    const setTimeoutIdQueueSize = await this.$store.dispatch('queue/size', 'setTimeoutIdQueue')
    for (let i = 0; i < setTimeoutIdQueueSize; i++) {
      const setTimeoutId = await this.$store.dispatch('queue/dequeue', 'setTimeoutIdQueue')
      clearTimeout(setTimeoutId)
    }

    // 5分、10分経過後にポイントを付与する
    this.noticeOrStackCurrentPageDelayed(10, { point: 5 })
    this.noticeOrStackCurrentPageDelayed(20, { point: 10 })
  }

  private get noticeCurrentPageText(): string {
    return this.$store.getters['drills/noticeCurrentPageText']
  }
}
