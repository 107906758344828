





















import { Component, Mixins, Vue } from 'vue-property-decorator'
import _ from 'lodash'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import DrillWebApi from '@/mixins/drills/WebApi'

@Component({
  components: {
    ButtonBase,
  },
})
export default class Transition extends Mixins(DrillWebApi) {
  public classMode(): string {
    return Vue.prototype.$cookies.get('dataGdls').classMode
  }

  protected get lessonId() {
    return this.$store.getters['lessonActionCable/lessonId']
  }

  private get drillData(): any {
    return this.$store.getters['drills/drillData']
  }

  private get isAssistMaterial(): boolean {
    return this.$route.query.classCategoryCode === 'HT'
  }
  private get listUrl(): string {
    return `/student/assist-material/select/${this.$route.query.curriculumModeCode}`
  }
  private get prevUrl(): boolean {
    return this.$store.getters['drills/prevUrl']
  }

  private get nextUrl(): boolean {
    Vue.prototype.$logger.info('-- Transition nextUrl')
    Vue.prototype.$logger.info(this.$store.getters['drills/nextUrl'])

    return this.$store.getters['drills/nextUrl']
  }

  private get prevPageType(): string {
    return this.$store.getters['drills/prevPageType']
  }

  private get nextPageType(): string {
    return this.$store.getters['drills/nextPageType']
  }

  private get isNextExercise(): boolean {
    if (this.$store.getters['drills/drillData'] === null) return false

    Vue.prototype.$logger.info('-- Transition isNextExercise')
    Vue.prototype.$logger.info(this.$store.getters['drills/drillData'].isNextExercise)

    return this.$store.getters['drills/drillData'].isNextExercise === true
      ? this.$store.getters['drills/drillData'].isNextExercise
      : false
  }

  private get isResultAllPage(): boolean {
    return this.$route.query.pageType === 'result_all'
  }

  private get nextText(): string {
    let text = ''

    switch (this.nextPageType) {
      case 'question':
        text = '次の問題'
        break
      case 'scoring_all':
      case 'scoring_unit':
        text = '答え合わせをする'
        break
      case 'result_all':
        if (this.$route.query.pageType === 'result_unit') {
          text = '結果を表示する'
        } else {
          if (this.curriculumModeCode === 'OO') {
            text = '結果を表示する'
          } else {
            text = '結果を登録する'
          }
        }
        break
      case 'result_unit':
        text = '結果を登録する'
    }

    // 次のページが存在しない場合項目リストや宿題・確認テスト一覧にとぶ
    // TODO 授業タイプやドリルのclass_category_typeによって正しく処理する（今は理解度テストと演習のみ対応）
    if (!this.nextPageType) {
      if (this.isNextExercise) {
        text = '演習問題を解く'
      } else {
        switch (this.classCategoryCode) {
          case 'EN':
            if (this.classMode() === 'TJ') {
              text = '項目リスト'
            } else if (this.classMode() === 'TK') {
              text = '一覧へ戻る'
            } else if (this.classMode() === 'KS') {
              text = '一覧へ戻る'
            } else if (this.classMode() === 'HJ') {
              text = '一覧へ戻る'
            } else if (this.classMode() === 'NY') {
              text = '一覧へ戻る'
            }
            break
          case 'SY':
            text = '宿題一覧へ戻る'
            break
          case 'KK':
            text = '確認テスト一覧へ戻る'
            break
          case 'HT':
            text = '一覧へ戻る'
            break
        }
      }
    }

    return text
  }

  /**
   * 正誤入力情報
   */
  private get correctInput(): any[] {
    return this.$store.getters['drills/correctInput']
  }

  /**
   * テキスト入力または選択したテキストの情報
   */
  private get inputSelectText(): any[] {
    return this.$store.getters['drills/inputSelectText']
  }

  /**
   * 授業種別コード
   *
   * @return 授業種別コード
   */
  private get classCategoryCode(): string {
    return Array.isArray(this.$route.query.classCategoryCode)
      ? this.$route.query.classCategoryCode[0] || ''
      : this.$route.query.classCategoryCode || ''
  }

  /**
   * カリキュラムモードコード
   *
   * @return カリキュラムモードコード
   */
  private get curriculumModeCode(): string {
    return Array.isArray(this.$route.query.curriculumModeCode)
      ? this.$route.query.curriculumModeCode[0] || ''
      : this.$route.query.curriculumModeCode || ''
  }

  protected mounted(): void {
    Vue.prototype.$logger.info('-- Transition mounted')
  }

  /**
   * ページの終了時に呼ぶメソッド
   * 問題表示中は問題終了APIをよび、解答時間を計測する
   *
   *
   */
  private async endPage(event: Event, prevNext: string): Promise<any> {
    Vue.prototype.$logger.info('-- Transition endPage')

    // 画面遷移するためロード終了は行わない
    Vue.prototype.$loading.start()

    switch (this.$route.query.pageType) {
      case 'question':
        // 問題終了APIを呼ぶ
        await this.endProblemApi({
          resultDrillId: Number(this.$route.query.resultDrillId),
          page: Number(this.$route.query.page),
        })

        // 一問一答の場合結果を登録する
        if (this.curriculumModeCode === 'OO') {
          let drillScoreData = this.drillData.data
          const inputSelectText = _.cloneDeep(this.inputSelectText)

          drillScoreData = drillScoreData.map((item: any, pageIndex: number) => {
            const problem = item.problems.map((problem: any, problemIndex: number) => {
              // 正誤入力情報は設問数分の解答を切り取って返す
              return { sProblemId: problem['sProblemId'], inputs: inputSelectText[pageIndex].splice(0, problem.number) }
            })

            return { page: item.page, problems: problem }
          })

          // 結果登録APIを呼ぶ
          await this.registerScoringUnitApi({
            lessonId: this.lessonId,
            resultDrillId: Number(this.$route.query.resultDrillId),
            page: Number(this.$route.query.page),
            data: drillScoreData,
          })
        }
        break
      case 'scoring_all':
      case 'scoring_unit': {
        let drillScoreData = this.drillData.data
        const correctInput = _.cloneDeep(this.correctInput)

        drillScoreData = drillScoreData.map((item: any, pageIndex: number) => {
          const problem = item.problems.map((problem: any, problemIndex: number) => {
            // 正誤入力情報は設問数分の解答を切り取って返す
            return { sProblemId: problem['sProblemId'], correct: correctInput[pageIndex].splice(0, problem.number) }
          })

          return { page: item.page, problems: problem }
        })

        // 一つでも未入力があればアラートを表示して正誤結果の登録と画面遷移を行わない
        const flattenCorrectInput: (boolean | null)[] = [].concat(...this.correctInput)
        if (flattenCorrectInput.includes(null)) {
          alert('全ての問題で○×をつけましょう！')
          Vue.prototype.$loading.complete()
          return
        }

        switch (this.$route.query.pageType) {
          case 'scoring_all':
            // 結果登録APIを呼ぶ
            await this.registerScoringAllApi({
              lessonId: this.lessonId,
              resultDrillId: Number(this.$route.query.resultDrillId),
              data: drillScoreData,
            })

            break
          case 'scoring_unit':
            // 結果登録APIを呼ぶ
            await this.registerScoringUnitApi({
              lessonId: this.lessonId,
              resultDrillId: Number(this.$route.query.resultDrillId),
              page: Number(this.$route.query.page),
              data: drillScoreData,
            })

            break
        }
        break
      }
      case 'result_all':
        if (this.isNextExercise) {
          const startDrill = await this.startDrillApi({
            lessonId: this.lessonId,
            classModeCode: this.classMode(),
            classCategoryCode: 'EN',
            curriculumSUnitId: Number(this.$route.query.curriculumSUnitId),
          })

          // TODO setNextUrlを直で変える処理は危ないため整理が必要
          this.$store.commit(
            'drills/setNextUrlDirect',
            `/student/drill?resultDrillId=${startDrill.resultDrillId}&pageType=${startDrill.pageType}&page=${startDrill.page}`
          )
        } else {
          // TODO setNextUrlを直で変える処理は危ないため整理が必要
          if (this.classCategoryCode === 'EN' && this.classMode() === 'TJ') {
            // 通常学習の演習の場合、小項目一覧画面に遷移
            this.$store.commit('drills/setNextUrlDirect', '/student/curriculum-s-units')
          } else if (this.classCategoryCode === 'EN' && this.classMode() === 'TK') {
            // 定期テストの演習の場合、教科選択画面に遷移
            this.$store.commit('drills/setNextUrlDirect', '/student/subjects/EN')
          } else if (this.classCategoryCode === 'EN' && this.classMode() === 'KS') {
            // 講習会の演習の場合、小項目一覧画面に遷移
            this.$store.commit('drills/setNextUrlDirect', '/student/seminar-unit-selection')
          } else if (this.classCategoryCode === 'HT') {
            // 補助教材のテストの場合、補助教材選択画面に遷移
            this.$store.commit('drills/setNextUrlDirect', `/student/assist-material/select/${this.curriculumModeCode}`)
          } else if (this.classCategoryCode === 'EN' && this.classMode() === 'NY') {
            // 入試対策の演習の場合、教科選択画面に遷移
            this.$store.commit('drills/setNextUrlDirect', '/student/subjects')
          } else {
            // 宿題・確認テストの場合、その一覧画面に遷移
            this.$store.commit('drills/setNextUrlDirect', `/student/confirmation/${this.classCategoryCode}`)
          }
        }
        break
    }

    if (this.$route.query.pageType === 'result_all' && !this.isNextExercise) {
      window.location.href = this[`${prevNext}Url`]
    } else if (this.$route.query.pageType === 'result_all') {
      // 小項目一覧に戻る場合以外は小項目IDをひき回す
      // 理解度テストから演習に遷移する場合は授業種別を演習に変更
      window.location.href =
        this[`${prevNext}Url`] + `&curriculumSUnitId=${this.$route.query.curriculumSUnitId}&classCategoryCode=EN`
    } else {
      // 小項目一覧に戻る場合以外は小項目IDと授業種別をひき回す
      window.location.href =
        this[`${prevNext}Url`] +
        `&curriculumSUnitId=${this.$route.query.curriculumSUnitId}&classCategoryCode=${this.classCategoryCode}&curriculumModeCode=${this.curriculumModeCode}`
    }
  }

  /**
   * 学習をやめるボタンを押した時の遷移先
   */
  private get suspensionUrl(): string {
    let result = ''

    // 問題ページの場合は中断しない
    if (this.$route.query.pageType === 'question') return result
    // 理解度テスト以外の全結果ページの場合は中断しない
    if (this.$route.query.pageType === 'result_all' && this.classCategoryCode !== 'RK') return result

    if (this.classCategoryCode === 'EN' && (this.classMode() === 'TJ' || this.classMode() === 'KS')) {
      // 通常授業と講習会の演習の場合、授業種別一覧画面に遷移
      result = `/student/curriculum-s-units/${this.$route.query.curriculumSUnitId}/class-categories`
    } else if (this.classCategoryCode === 'RK') {
      // 理解度テストの場合、授業種別一覧画面に遷移
      result = `/student/curriculum-s-units/${this.$route.query.curriculumSUnitId}/class-categories`
    } else if (this.classCategoryCode === 'EN' && this.classMode() === 'TK') {
      // 定期テストの演習の場合、教科選択画面に遷移
      result = '/student/subjects/EN'
    } else if (this.classCategoryCode === 'SY') {
      // 宿題の場合、その一覧画面に遷移
      result = `/student/confirmation/${this.classCategoryCode}`
    }

    return result
  }

  /**
   * 学習をやめるボタンを押した時の遷移
   */
  private async suspensionTransition() {
    // router.pushの方が早いですが、javascriptの変数のキャッシュにより挙動が変わるため通常の画面遷移としておきます。
    if (this.suspensionUrl !== '') window.location.href = this.suspensionUrl
  }
}
