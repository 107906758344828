















import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {},
})
export default class ImageExpansionAudioDoc extends Vue {
  private show = false

  private pageIndex: number | null = null

  public showImage(pageIndex: number): void {
    this.pageIndex = pageIndex
    this.show = true
  }

  public hideImage(): void {
    this.pageIndex = null
    this.show = false
  }

  /**
   * Web APIを通して取得した画像URLを取得
   * 問題ページの場合も配列に1つの連想配列が渡ってくる
   * [{d: '...', m: '...', j: '...', h: '....'}]
   *
   * @return 画像URL群
   */
  private get imageUrls(): any[] {
    return this.$store.getters['drills/imageUrls']
  }

  /**
   * リスニング音声原稿の画像URLを取得
   *
   * @return リスニング音声原稿の画像URLの配列
   */
  private get audioDoc(): any[] {
    if (this.pageIndex === null) return []
    if (!this.imageUrls) return []
    if (!this.imageUrls[this.pageIndex]) return []

    return this.imageUrls[this.pageIndex].h || []
  }
}
